import { host } from "api_client/client";

class InternalApiClient {
  async postTransactionSession(transactionSessionData) {
    const response = await fetch(`${host}transaction-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(transactionSessionData),
    });
    return await response.json();
  }
}

export default InternalApiClient;
