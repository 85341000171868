import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useSimpleContext } from "contexts/SimpleContext";
import { CardBrandRepresentationForAdyenCode } from "modules/adyenConfig";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "@adyen/adyen-web/styles/adyen.css";
import { Button } from "reactstrap";
import AdyenPaymentSessionController from "./adyenPaymentSessionController";
import InternalApiClient from "api_client/rest/internalApiClient";
import InternalApiAdyenClient from "api_client/rest/adyen/internalApiAdyenClient";
import { floatToMinorUnits } from "modules/data_conversions";
import { regionCode, currencyForRegion } from "modules/constants";
import { PulseLoader } from "react-spinners";

function updateContext(
  context,
  setContext,
  customerCreditCard,
  adyenPaymentSessionController
) {
  const debitType = context.transaction
    ? "transaction"
    : context.recurringPlan
    ? "recurringPlan"
    : null;
  if (!debitType) {
    throw new Error("No debit type found in context");
  }
  const updatedCustomerCreditCard = {
    ...context[debitType].customerCreditCard,
    ...customerCreditCard,
    adyenPaymentSessionController: adyenPaymentSessionController,
  };
  const updatedTransaction = {
    ...context[debitType],
    customerCreditCard: updatedCustomerCreditCard,
  };
  setContext({
    ...context,
    [debitType]: updatedTransaction,
  });
}

function AdyenCreditCardMethod({
  onCreditCardSuccessPageobject,
  adyenComponentDefaults,
  adyenClientKey,
  adyenEnvironment,
}) {
  const [context, setContext] = useSimpleContext();
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const [customerCreditCard, setCustomerCreditCard] = useState("");
  const [cardDetails, setCardDetails] = useState(null);
  const [adyenPaymentSessionController] = useState(
    new AdyenPaymentSessionController(
      new InternalApiClient(),
      new InternalApiAdyenClient(),
      CardBrandRepresentationForAdyenCode,
      setCardDetails
    )
  );
  const componentRef = useRef(null);
  // todo: this is just a workaround but this component should be independent of where this comes from
  const debitFrequencyObj = context.transaction || context.recurringPlan;
  /*
   * In the case of recurring plans, we only want to perform a zero-value auth for tokenization purposes.
   * API Doc: https://docs.adyen.com/get-started-with-adyen/payment-glossary/#zero-value-auth
   */
  const amount = context?.recurringPlan ? 0 : debitFrequencyObj.amount;
  const transactionId = context?.transaction?.identifier;

  //todo: replace with region query on bankAccount from internal API
  const resolveRegion = (bankAccount) => {
    return bankAccount.caRoutingInfo ? regionCode.CA : regionCode.US;
  };

  const region = resolveRegion(debitFrequencyObj.toBankAccount).toUpperCase();
  const transactionSessionData = {
    contactId: debitFrequencyObj.contact.identifier,
    toBankAccountId: debitFrequencyObj.toBankAccount.identifier,
    amount: amount,
    redirectUrl: adyenComponentDefaults.redirectUrl,
    tokenizePaymentMethod: !!context.recurringPlan,
    countryCode: region,
  };

  const checkoutConfigurationData = {
    clientKey: adyenClientKey,
    environment: adyenEnvironment,
    amount: {
      value: floatToMinorUnits(amount),
      currency: currencyForRegion[region],
    },
    locale: adyenComponentDefaults.locale,
    countryCode: region,
  };

  const cardConfigurationData = {
    enableStoreDetails: adyenComponentDefaults.enableStoreDetails,
  };

  const sessionConfigurationData = {
    transactionSessionData,
    transactionId,
    checkoutConfigurationData,
    cardConfigurationData,
  };

  useEffect(async () => {
    setLoading(true);
    await adyenPaymentSessionController.setupAdyenComponent(
      sessionConfigurationData,
      componentRef
    );
    setLoading(false);
  }, [componentRef]);

  useEffect(() => {
    if (customerCreditCard) {
      updateContext(
        context,
        setContext,
        customerCreditCard,
        adyenPaymentSessionController
      );
      history.push(onCreditCardSuccessPageobject);
    }
  }, [customerCreditCard]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setCustomerCreditCard(cardDetails);
  };

  return (
    <>
      <div ref={componentRef} className="pb-4"></div>
      <Button
        block
        onClick={handleSubmit}
        disabled={!cardDetails}
        className="btn-primary btn-lg mt-0 mb-3"
      >
        {isLoading ? <PulseLoader color="white" size={10} /> : "Review & pay"}
      </Button>
    </>
  );
}

AdyenCreditCardMethod.propTypes = {
  onCreditCardSuccessPageobject: PropTypes.object.isRequired,
  adyenComponentDefaults: PropTypes.object.isRequired,
  adyenClientKey: PropTypes.string.isRequired,
  adyenEnvironment: PropTypes.string.isRequired,
};

export default AdyenCreditCardMethod;
