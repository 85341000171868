import { useEffect, useState } from "react";
import defaultImage from "assets/img/image_placeholder.jpg";
import useGQL from "../api_client/UseGQL";
import { updateDisplayImage } from "../api_client/mutations/companyProfile";
import { useToasts } from "react-toast-notifications";
import {
  updateBankStatementDocument,
  updateIncorporationDocument,
  updateInvoiceDocument,
  updateArticleIncorporationDocument,
  updateAmendmentsDocument,
  updateAprAndLenderUpload,
} from "../api_client/mutations/verification";
import useApiValue from "./useApiValue";

const useImageUpload = (
  fileInputRef,
  companyImage,
  type = "PROFILE",
  document = ""
) => {
  const [imageState, setImageState] = useState({
    file: null,
    imagePreviewUrl: defaultImage,
  });
  let gqlHooks = useGQL();
  const [imageLoading, setLoading] = useState(true);
  const { addToast } = useToasts();
  const { update } = useApiValue();

  useEffect(() => {
    setImageState({
      ...imageState,
      imagePreviewUrl: companyImage ? companyImage : defaultImage,
    });
    setLoading(false);
  }, [companyImage]);

  async function handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    if (file.name.length > 50) {
      addToast("File name must be less than 50 characters", {
        appearance: "warning",
        autoDismiss: true,
      });
      return;
    }
    if (file.size >= 2621440) {
      addToast(
        "Image must be less than 2.5mb. Please select a different image or reduce the size",
        {
          appearance: "warning",
          autoDismiss: true,
        }
      );
      fileInputRef.current.value = "";
      return false;
    }
    reader.onloadend = () => {
      setImageState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  }

  async function imageUpload(file) {
    let output = {
      company: {
        displayImage: true,
      },
    };
    let response = await updateDisplayImage({ file: file }, output, gqlHooks);
    if (response) {
      update(response);
      setImageState({
        file: null,
        imagePreviewUrl: response.company.displayImage,
      });
      return true;
    } else {
      return false;
    }
  }

  async function documentUpload(file) {
    const documentResponse = await updateIncorporationDocument(
      { file: file },
      {},
      gqlHooks
    );
    return !!documentResponse;
  }

  async function bankStatementUpload(file, statementNumber) {
    const documentResponse = await updateBankStatementDocument(
      { file: file },
      {},
      gqlHooks,
      statementNumber
    );
    return !!documentResponse;
  }

  async function customerInvoiceUpload(file) {
    const documentResponse = await updateInvoiceDocument(
      { file: file },
      {},
      gqlHooks
    );
    return !!documentResponse;
  }

  async function articleIncorporationUpload(file) {
    const documentResponse = await updateArticleIncorporationDocument(
      { file: file },
      {},
      gqlHooks
    );
    return !!documentResponse;
  }

  async function amendmentsDocumentUpload(file) {
    const documentResponse = await updateAmendmentsDocument(
      { file: file },
      {},
      gqlHooks
    );
    return !!documentResponse;
  }

  async function aprAndLenderUpload(file) {
    const documentResponse = await updateAprAndLenderUpload(
      { file: file },
      {},
      gqlHooks
    );
    return !!documentResponse;
  }

  const handleImageSubmit = async () => {
    setLoading(true);
    let response;
    if (type === "PROFILE") {
      response = await imageUpload(imageState.file);
    } else if (type === "STATEMENTS") {
      response = await bankStatementUpload(imageState.file, document);
    } else if (type === "INVOICE") {
      response = await customerInvoiceUpload(imageState.file);
    } else if (type === "ARTICLE") {
      response = await articleIncorporationUpload(imageState.file);
    } else if (type === "AMENDMENTS") {
      response = await amendmentsDocumentUpload(imageState.file);
    } else if (type === "APRANDLENDER") {
      response = await aprAndLenderUpload(imageState.file);
    } else {
      response = documentUpload(imageState.file);
    }
    setLoading(false);
    return response;
  };

  function handleClick() {
    fileInputRef.current.click();
  }

  return {
    handleImageChange,
    handleImageSubmit,
    handleClick,
    imageLoading,
    imageState,
    setImageState,
  };
};

export default useImageUpload;
