import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSimpleContext } from "../../../contexts/SimpleContext";
import { useHistory } from "react-router";
import { Col, Container, Row } from "reactstrap";
import PaymentBackTitle from "../components/PaymentBackTitle";
import { useMixPanelGQL } from "../../../api_client/UseGQL";
import { trackEvent } from "../../../modules/analytics";
import { useCreditCardHandler } from "./hooks/creditCardHandler";
import AdyenCreditCardMethod from "./AdyenCreditCardMethod";
import {
  AdyenComponentDefaults,
  adyenClientKey,
  adyenEnvironment,
} from "modules/adyenConfig";
import StripeCreditCardMethod from "./StripeCreditCardMethod";
import ConvergeCreditCardMethod from "./ConvergeCreditCardMethod";
import { Vendor, InternalApiPaymentMethodCode } from "modules/constants";
import { getPaymentMethodFromArray } from "modules/parsers";

function vendorCreditCardIframeComponentFactory(vendor) {
  switch (vendor) {
    case Vendor.ADYEN: {
      return (
        <AdyenCreditCardMethod
          onCreditCardSuccessPageobject={{
            pathname: "review",
            search: "?paymentMethod=creditCard",
          }}
          adyenComponentDefaults={AdyenComponentDefaults}
          adyenClientKey={adyenClientKey}
          adyenEnvironment={adyenEnvironment}
        />
      );
    }
    case Vendor.STRIPE: {
      return <StripeCreditCardMethod />;
    }
    case Vendor.CONVERGE: {
      return <ConvergeCreditCardMethod />;
    }
    default:
      console.error("Unknown vendor: ", vendor);
  }
}

function CreditCardMethod({ paymentMethods }) {
  const [context] = useSimpleContext();
  const [, setErrorMsg] = useState("");
  const history = useHistory();
  const mixPanelGqlHooks = useMixPanelGQL();
  const { alert } = useCreditCardHandler(setErrorMsg);
  const paymentMethodEnum = InternalApiPaymentMethodCode.CREDIT_CARD;
  const paymentMethod = getPaymentMethodFromArray(
    paymentMethods,
    paymentMethodEnum
  );
  const vendor = paymentMethod.vendor;
  const vendorCreditCardIframeComponent =
    vendorCreditCardIframeComponentFactory(vendor);

  useEffect(() => {
    const properties = context.transaction
      ? {
          transactionId: context.transaction.identifier,
          companyId: context.transaction.company?.identifier,
          contactId: context.transaction.contact.identifier,
        }
      : context.recurringPlan
      ? {
          transactionId: context.recurringPlan.identifier,
          companyId: context.recurringPlan.company.identifier,
          contactId: context.recurringPlan.contact.identifier,
        }
      : undefined;

    properties && trackEvent("debitCreditMethod", properties, mixPanelGqlHooks);
  }, []);

  const checkoutFlow = context?.transaction || context?.recurringPlan;
  const goBackToMethodPage = checkoutFlow?.company.settings.eftEnabled;

  return (
    <Fragment>
      {alert}
      <PaymentBackTitle
        title="Pay via Credit Card"
        backAction={
          goBackToMethodPage
            ? () => history.push("method")
            : () => history.push("details")
        }
      />
      <Container>
        <Row>
          <Col md="8" lg="8" className="m-auto pb-3">
            {vendorCreditCardIframeComponent}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default CreditCardMethod;

CreditCardMethod.propTypes = {
  paymentMethods: PropTypes.array,
};
