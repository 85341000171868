import { host } from "api_client/client";

class InternalApiAdyenClient {
  async postAdyenSession(sessionDataString) {
    const response = await fetch(`${host}adyen/adyen-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ session_data: sessionDataString }),
    });
    return await response.json();
  }

  async postAdyenSessionResult(
    brand,
    endDigits,
    contactId,
    transactionId,
    resultCode,
    sessionId
  ) {
    const response = await fetch(`${host}adyen/adyen-session-result`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        brand: brand,
        end_digits: endDigits,
        contact_id: contactId,
        transaction_id: transactionId,
        result_code: resultCode,
        session_id: sessionId,
      }),
    });
    return await response.json();
  }
}

export default InternalApiAdyenClient;
